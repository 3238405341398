import { IconButton } from '@droplet-tech-code/core-elements/module/ui/Button';
import { PageHeader } from '~/components/Page/Page.Header';
import { translate } from '~/utils/translation.utils';

export const MetaSearchJobItemHeader = ({ onPress }: { onPress: () => void }) => {
  return (
    <PageHeader
      title={translate('dataManagement.metaSearchJobs.searchJobItemHeader')}
      childRight={<IconButton color="monochrome" icon="close" onPress={onPress} />}
    />
  );
};
