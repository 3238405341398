import { ConfigForData, SelectedValueItem } from '@droplet-tech-code/core-common-types';
import { useAppTheme } from '@droplet-tech-code/core-elements/module/theme';
import { Button } from '@droplet-tech-code/core-elements/module/ui/Button';
import { DelayRender } from '@droplet-tech-code/core-elements/module/ui/Delay';
import { Divider } from '@droplet-tech-code/core-elements/module/ui/Divider/Divider';
import {
  FormBuilder,
  FormHandler,
} from '@droplet-tech-code/core-elements/module/ui/Inputs/Form';
import { SelectInputBase } from '@droplet-tech-code/core-elements/module/ui/Inputs/SelectInput/SelectInput';
import {
  Modal,
  useModal,
  useModalVerify,
} from '@droplet-tech-code/core-elements/module/ui/Modal';
import { HStack, VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { Toggle } from '@droplet-tech-code/core-elements/module/ui/Toggle/Toggle';
import { ScrollView, View } from '@droplet-tech-code/core-elements/module/ui/View';
import {
  ErrorObject,
  handleResponse,
} from '@droplet-tech-code/core-elements/module/utils/error';
import { isWeb } from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import {
  PurchaseClientAutoUpdateRequest,
  PurchaseClientAutoUpdateResponse,
  PurchaseClientModifyProviderResponse,
  PurchaseClientWithMetaWithTickets,
  PurchaseListClientModifyManualRequest,
  PurchaseUpdate,
} from '@naus-code/naus-admin-types';
import { RoutePriceV2 } from '@naus-code/naus-client-types';
import { useEffect, useRef, useState } from 'react';

import { BasketTotalBase } from '~/components/BasketManager/BasketManager.Pricing';
import {
  BasketManagerProvider,
  useBasketManager,
  useBasketManagerStore,
} from '~/components/BasketManager/BasketManager.Provider';
import { TicketsPricingProvider } from '~/components/BasketManager/utils/ticketPricing/TicketPricing.Provider';
import { useJsonViewerModal } from '~/components/JsonView';
import { PaymentSessionSelector } from '~/screens/Support/Purchases/PurchaseActions/ActionPanel/Common/ActionPanel.PaymentSession';
import { PurchaseTicketsToSelect } from '~/screens/Support/Purchases/PurchaseActions/ActionPanel/Common/ActionPanel.TicketSelector';
import { purchaseActionManager } from '~/screens/Support/Purchases/PurchaseActions/ActionPanel/Common/ActionPanel.utils';
import {
  ModifyPanelProps,
  resetBasketManager,
  useModifyData,
  useModifyInitial,
} from '~/screens/Support/Purchases/PurchaseActions/ActionPanel/Modify/Modify.utils';
import { translate } from '~/utils/translation.utils';

import { getPriceWithSymbol } from '../../../purchase.utils';
import { AutoUpdateResponseMessage } from '../../../PurchaseFromProvider/PurchaseFromProvider.Compare';
import { purchaseApi } from '../../../Purchases.api';
import { purchaseActionApi } from '../../PurchaseActions.api';
import { ApiTripManager } from './Api/Modify.ApiTripManager';
import { useModifyAutoUpdateStore } from './Auto-update/Modify.AutoUpdate.State';
import { AutoUpdateTripManagerWrapper } from './Auto-update/Modify.AutoUpdateTripManager';
import {
  ManualModificationProvider,
  useManualStore,
} from './Manual/ManualProvider/Modify.ManualTrip.Provider';
import { ManualTripManager } from './Manual/Modify.ManualTripManager';
import { createManualUpdatePurchases } from './Manual/utils/Modify.ManualTrip.utils';
import { Modals } from './Modify.Tickets';
import { useIsSystemUser } from '~/hooks/auth.hook';

export const ModifyModalKey = 'useModifyPanel';

export type ModificationType = 'Api' | 'Manual' | 'Auto-update';

export const ModifyPanel = (props: ModifyPanelProps) => {
  const { closeModal } = useModal();
  const { manuallySelectedRoute } = useModifyData(props);

  const [option, setOption] = useState<ModificationType>('Auto-update');

  return (
    <Modal.Container style={{ maxHeight: 1000 }}>
      <BasketManagerProvider>
        <TicketsPricingProvider>
          <ManualModificationProvider>
            <Modal.Header
              divider
              title={translate('purchases.purchaseTool.modifyPurchase')}
              onPress={() => {
                closeModal(ModifyModalKey);
              }}
            />
            <Modal.Body flex={1}>
              <ModifyPanelBody {...{ ...props, option, setOption }} />
            </Modal.Body>
            <ModifyFooter {...{ ...props, option }} />
            <Modals manuallySelectedRoute={manuallySelectedRoute} />
          </ManualModificationProvider>
        </TicketsPricingProvider>
      </BasketManagerProvider>
    </Modal.Container>
  );
};

const ModifyFooter = (
  props: ModifyPanelProps & {
    option: ModificationType;
  },
) => {
  const { option } = props;
  const clearAll = useClearAll();
  const basketManager = useBasketManager();

  const manualModStore = useManualStore();
  const modificationForms = manualModStore((s) => s.modificationForms);
  const [silentOn, setSilentOn] = useState(false);
  const [refreshPDF, setRefreshPDF] = useState(false);
  const { openModal, closeModal } = useModal();

  const [modifyPurchaseApi, { isLoading: isModifyingApi }] =
    purchaseActionApi.useModifyPurchaseFromProviderMutation();
  const [modifyPurchaseAuto, { isLoading: isModifyingAuto }] =
    purchaseActionApi.useUpdatePurchaseProviderMutation();
  const [modifyPurchaseManual, { isLoading: isModifyingManual }] =
    purchaseActionApi.useUpdatePurchaseManualMutation();

  const { verifyAction } = useModalVerify();

  const { disabled, selectedTickets, purchaseRequest, providerOptions, purchases } =
    useModifyData(props);
  const [paymentSessionIds] =
    purchaseActionManager.state.useGlobalState('paymentSessionIds');
  const [selectedReservation] =
    purchaseActionManager.state.useGlobalState('selectedReservation');

  const footerButton = useBasketManagerStore()((s) => s.basket.pricing.validation);

  const isModifying = isModifyingApi || isModifyingAuto || isModifyingManual;

  const disableFooterButton =
    disabled ||
    isModifying ||
    (footerButton.status !== 'valid' && option === 'Api') ||
    ((!purchaseRequest || !providerOptions) && option === 'Auto-update');

  const onModifyManual = async () => {
    const relevantPurchases = purchases?.filter(
      (purchase) => purchase.companyReservationCode === selectedReservation,
    );

    const purchasesToUpdateData = createManualUpdatePurchases(
      modificationForms,
      selectedTickets,
      relevantPurchases as PurchaseClientWithMetaWithTickets[],
    );
    if (!purchasesToUpdateData) {
      return { message: 'Dashboard Error: Could not amend manually' } as ErrorObject;
    }
    const { manualModificationPurchases, operatorFareDifference, amendmentDatetime } =
      purchasesToUpdateData;

    return await modifyPurchaseManual({
      purchasesToUpdate: manualModificationPurchases,
      operatorFareDifference,
      silentUpdate: silentOn,
      amendmentDatetime,
      paymentSessionIds,
      supportId: props.supportId,
    } as PurchaseListClientModifyManualRequest);
  };

  return (
    <Modal.Footer
      confirmProps={{
        disabled: disableFooterButton && option != 'Manual',
        isLoading: isModifying,
      }}
      divider
      confirm={translate(
        silentOn
          ? 'purchases.purchaseTool.silentlyModify'
          : 'purchases.purchaseTool.modifyTickets',
      )}
      onConfirm={async () => {
        let routePrice;

        if (option === 'Api') {
          routePrice = basketManager.getSessionRequestData().routePrice;
          if (!routePrice) {
            return;
          }
        }

        if (!purchaseRequest && !providerOptions && option === 'Auto-update') {
          return;
        }

        if (!purchases && option === 'Manual') {
          return;
        }

        const modifyPurchase = async () => {
          switch (option) {
            case 'Auto-update':
              return await modifyPurchaseAuto({
                supportId: props.supportId,
                purchaseId: props.purchaseId,
                silentUpdate: silentOn,
                refreshPdf: refreshPDF,
                purchase: purchaseRequest as PurchaseUpdate,
                providerOptions,
              } as PurchaseClientAutoUpdateRequest);

            case 'Manual': {
              return await onModifyManual();
            }
            default:
              return await modifyPurchaseApi({
                supportId: props.supportId,
                purchaseId: props.purchaseId,
                ticketIds: selectedTickets,
                silentUpdate: silentOn,
                paymentSessionIds,
                routePrice,
              });
          }
        };

        if (paymentSessionIds.length === 0) {
          verifyAction({
            title:
              'You have not selected any payment session.\n Are you sure you want to continue?',
            description:
              'You have not selected any payment session associated to the modification,\n this is only done when making a modification in good will.',
          }).then(async (value) => {
            if (value) {
              const response = await modifyPurchase();
              const ModifyModalKeyResponse = 'modifyPurchaseResponse';
              handleResponse({
                response,
                onError: () => {},
                onSuccess: async ({ data }) => {
                  if (option !== 'Manual') {
                    openModal(
                      <Modal.Container>
                        <Modal.Header
                          title="Modification response"
                          onPress={() => {
                            closeModal(ModifyModalKeyResponse);
                          }}
                          divider
                        />
                        <Modal.Body p="3">
                          {option === 'Api' && (
                            <ApiResponseMessage
                              data={data as PurchaseClientModifyProviderResponse}
                              routePrice={routePrice}
                            />
                          )}
                          {option === 'Auto-update' && (
                            <AutoUpdateResponseMessageWrapper
                              data={data as PurchaseClientAutoUpdateResponse}
                            />
                          )}
                        </Modal.Body>
                      </Modal.Container>,
                      { key: ModifyModalKeyResponse },
                    );
                  }
                  closeModal(ModifyModalKey);

                  clearAll();
                },
              });
            }
          });
        } else {
          const response = await modifyPurchase();
          const ModifyModalKeyResponse = 'modifyPurchaseResponse';
          handleResponse({
            response,
            onError: () => {},
            onSuccess: async ({ data }) => {
              if (option !== 'Manual') {
                openModal(
                  <Modal.Container>
                    <Modal.Header
                      title="Modification response"
                      onPress={() => {
                        closeModal(ModifyModalKeyResponse);
                      }}
                      divider
                    />
                    <Modal.Body p="3">
                      {option === 'Api' && (
                        <ApiResponseMessage
                          data={data as PurchaseClientModifyProviderResponse}
                          routePrice={routePrice}
                        />
                      )}
                      {option === 'Auto-update' && (
                        <AutoUpdateResponseMessageWrapper
                          data={data as PurchaseClientAutoUpdateResponse}
                        />
                      )}
                    </Modal.Body>
                  </Modal.Container>,
                  { key: ModifyModalKeyResponse },
                );
              }
              closeModal(ModifyModalKey);

              clearAll();
            },
          });
        }
      }}
    >
      <HStack flex={1} space="3">
        {footerButton.error ? (
          <Text.Body2Medium color="error-mid">{footerButton.error}</Text.Body2Medium>
        ) : null}
        <View flex={1} />
        {option === 'Auto-update' && (
          <HStack space="1">
            <Toggle
              color="primary-mid"
              value={refreshPDF}
              onChange={(v) => setRefreshPDF(v)}
            />
            <Text.Body2Regular color="monochrome-extraDark">
              {translate('purchases.purchaseTool.purchaseCompare.refreshPDF')}
            </Text.Body2Regular>
          </HStack>
        )}
        <HStack space="1">
          <Toggle color="primary-mid" value={silentOn} onChange={(v) => setSilentOn(v)} />
          <Text.Body2Regular color="monochrome-extraDark">
            {translate('purchases.purchaseTool.purchaseCompare.silentUpdate')}
          </Text.Body2Regular>
        </HStack>

        <BasketTotalBase />
      </HStack>
    </Modal.Footer>
  );
};

const ModifyPanelBody = (
  props: ModifyPanelProps & {
    option: ModificationType;
    setOption: (o: ModificationType) => void;
  },
) => {
  const { spacing } = useAppTheme();
  const { isLoadingBookingData, isSupportLoading } = useModifyInitial(props);
  return (
    <DelayRender isLoading={isLoadingBookingData || isSupportLoading}>
      <HStack
        space="6"
        align="flex-start"
        style={{ alignSelf: 'flex-start', height: '100%' }}
        flex={1}
      >
        <ScrollView
          flex={1}
          style={{
            alignSelf: 'stretch',
            paddingHorizontal: spacing[4],
            paddingVertical: spacing[4],
          }}
        >
          <PurchaseTicketsToSelectQuery {...{ ...props, option: props.option }} />
        </ScrollView>
        <Divider vertical />
        <ScrollView
          style={{
            alignSelf: 'stretch',
            width: 1000,
            paddingHorizontal: spacing[3],
            paddingTop: spacing[3],
            paddingBottom: spacing[6],
          }}
        >
          <TripManagerContainer {...props} />
        </ScrollView>
      </HStack>
    </DelayRender>
  );
};

const PurchaseTicketsToSelectQuery = (
  props: ModifyPanelProps & { option: ModificationType },
) => {
  const { purchase, supportRequest, isLoading } = useModifyData(props);

  if (!supportRequest) {
    return null;
  }

  if (props.option === 'Manual') {
    return <ManualTicketSelector {...props} />;
  }

  if (!purchase) {
    return null;
  }

  return (
    <DelayRender isLoading={isLoading} noDelay loadingProps={{ style: { opacity: 0 } }}>
      <PurchaseTicketsToSelect
        noBorder
        title={translate('purchases.purchaseTool.selectTicketsToModify')}
        purchase={purchase}
        supportRequest={supportRequest}
      />
    </DelayRender>
  );
};

const ManualTicketSelector = (props: ModifyPanelProps & { option: ModificationType }) => {
  const { supportRequest, isLoading, purchases } = useModifyData(props);

  const form = new FormHandler();
  const formRef = useRef(form);

  const [selectedReservation, setSelectedReservation] =
    purchaseActionManager.state.useGlobalState('selectedReservation');

  const [_checkedItems, setCheckedItems] =
    purchaseActionManager.state.useGlobalState('selectedTickets');

  const supportPurchaseId = supportRequest?.purchaseId;

  const selectedRes =
    purchases?.find((purchase) => purchase._id === supportPurchaseId)
      ?.companyReservationCode ||
    purchases?.at(0)?.companyReservationCode ||
    '';

  useEffect(() => {
    setSelectedReservation(selectedRes);
  }, []);

  form.subscribeToValueChange((id, value) => {
    if (id === 'reservationCode') {
      setSelectedReservation(value);
      setCheckedItems([]);
    }
  });

  const reservations = new Set<string>();
  const reservationList = [] as SelectedValueItem[];

  if (!purchases) {
    return null;
  }

  for (const purchase of purchases) {
    reservations.add(`${purchase.companyReservationCode}-${purchase.companyId}`);
  }

  reservations.forEach((reservation) =>
    reservationList.push({ label: reservation, value: reservation.split('-')[0] }),
  );

  const config: ConfigForData = [
    {
      items: [
        {
          id: 'reservationCode',
          type: 'single-select',
          label: 'Select Reservation',
          list: reservationList,
          initialValue: selectedRes,
        },
      ],
    },
  ];

  if (!supportRequest) {
    return null;
  }

  return (
    <DelayRender isLoading={isLoading} noDelay loadingProps={{ style: { opacity: 0 } }}>
      <VStack>
        <FormBuilder data={config} form={formRef.current} />
        {purchases?.map((purchaseItem) => {
          if (purchaseItem?.companyReservationCode !== selectedReservation) {
            return null;
          }
          return (
            <PurchaseTicketsToSelect
              key={purchaseItem._id}
              noBorder
              title={translate('purchases.purchaseTool.selectTicketsToModify')}
              purchase={purchaseItem}
              supportRequest={supportRequest}
              isManual
            />
          );
        })}
      </VStack>
    </DelayRender>
  );
};

const TripManagerContainer = (
  props: ModifyPanelProps & {
    option: ModificationType;
    setOption: (o: ModificationType) => void;
  },
) => {
  const { option, setOption } = props;
  const { data } = purchaseApi.useGetPurchaseQuery(props.purchaseId);
  const isDirectFerries = data?.purchase.provider === 'DIRECT_FERRIES';
  return (
    <VStack space="1">
      <VStack space="1">
        <ModificationTypeSelector
          option={option}
          setOption={setOption}
          isDirectFerries={isDirectFerries}
        />
        <Text.Small color="monochrome-dark">
          {translate('purchases.purchaseTool.paymentSession')}
        </Text.Small>
        <PaymentSessionSelector {...props} />
      </VStack>
      <Selection option={option} props={props} />
    </VStack>
  );
};

const ModificationTypeSelector = ({
  isDirectFerries,
  option,
  setOption,
}: {
  isDirectFerries: boolean;
  option: ModificationType;
  setOption: (option: ModificationType) => void;
}) => {
  const isSystemUser = useIsSystemUser();

  const options = [
    { value: 'Auto-update' },
    { value: 'Manual' },
    { label: 'Customer & Provider', value: 'Api' },
  ].filter((option) => {
    if (option.value === 'Manual') {
      return isDirectFerries || isSystemUser;
    }
    if (option.value === 'Api') {
      return !isDirectFerries;
    }
    return true;
  });

  return (
    <SelectInputBase
      noFeedback
      feedback=""
      values={[option]}
      type="single-select"
      setValues={(option) => {
        if (option?.at(0)) {
          setOption(option.at(0) as ModificationType);
        }
      }}
      options={options}
    />
  );
};

const Selection = ({
  option,
  props,
}: {
  option: ModificationType;
  props: ModifyPanelProps;
}) => {
  const [selectedTickets] = purchaseActionManager.state.useGlobalState('selectedTickets');

  if (selectedTickets.length === 0 && option != 'Manual') {
    return null;
  }

  switch (option) {
    case 'Api':
      return <ApiTripManager {...props} />;
    case 'Manual':
      return <ManualTripManager {...props} />;
    case 'Auto-update':
      return <AutoUpdateTripManagerWrapper {...props} />;
  }
};

export const useModifyPanel = () => {
  const { openModal, closeModal } = useModal();

  return {
    open: (props: ModifyPanelProps) => {
      purchaseActionManager.reset();

      openModal(<ModifyPanel {...props} />, {
        key: ModifyModalKey,
        modalStyle: isWeb
          ? {
              minWidth: '',
              maxWidth: '',
            }
          : undefined,
      });
    },
    close: () => {
      closeModal(ModifyModalKey);
    },
  };
};

export const useClearAll = () => {
  const setResCode = useModifyAutoUpdateStore((s) => s.setResCode);

  return () => {
    setResCode('');
    purchaseActionManager.reset();
    resetBasketManager();
  };
};

const AutoUpdateResponseMessageWrapper = ({
  data,
}: {
  data: PurchaseClientAutoUpdateResponse;
}) => {
  const jsonViewer = useJsonViewerModal();

  return (
    <VStack>
      <AutoUpdateResponseMessage data={data as PurchaseClientAutoUpdateResponse} />
      <Button
        variant="outline"
        onPress={() => {
          jsonViewer.open({ data, title: 'Purchase after modification' });
        }}
      >
        {translate('purchases.purchaseTool.viewPurchase')}
      </Button>
    </VStack>
  );
};

const ApiResponseMessage = ({
  data,
  routePrice,
}: {
  data: PurchaseClientModifyProviderResponse;
  routePrice: RoutePriceV2;
}) => {
  const jsonViewer = useJsonViewerModal();

  return (
    <VStack space="3">
      <HStack space="6">
        <Text.Small color="monochrome-extraDark" type="small">
          Credit before:
        </Text.Small>
        <Text.Body2Regular>
          {getPriceWithSymbol(data.creditBefore, routePrice.ccy)}
        </Text.Body2Regular>
      </HStack>
      <HStack space="6">
        <Text.Small color="monochrome-extraDark" type="small">
          {translate('purchases.purchaseTool.creditAfter')}
        </Text.Small>
        <Text.Body2Regular>
          {getPriceWithSymbol(data.creditAfter, routePrice.ccy)}
        </Text.Body2Regular>
      </HStack>
      <HStack space="6">
        <Text.Small color="monochrome-extraDark" type="small">
          {translate('purchases.purchaseTool.valueOfModification')}
        </Text.Small>
        <Text.Body2Regular>
          {getPriceWithSymbol(data.valueOfModifyProvider, routePrice.ccy)}
        </Text.Body2Regular>
      </HStack>

      <Button
        variant="outline"
        onPress={() => {
          jsonViewer.open({ data, title: 'Purchase after modification' });
        }}
      >
        {translate('purchases.purchaseTool.viewPurchase')}
      </Button>
    </VStack>
  );
};
