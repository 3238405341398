import { VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { MetaSearchJobClient, MetaSearchJobDetails } from '@naus-code/naus-admin-types';
import { CardWithHeader } from '~/components/Card/CardWithHeader';
import { translate } from '~/utils/translation.utils';
import { MetaSearchJobItemHeader } from './MetaSearchJob.itemHeader';
import { Field } from '~/components/Card/Field';
import { Table } from '~/components/Table/Table';

export const MetaSearchJobItem = ({
  metaSearchJob,
  onClose,
}: {
  metaSearchJob: MetaSearchJobClient;
  onClose: () => void;
}) => {
  return (
    <VStack space="2.5">
      <MetaSearchJobItemHeader onPress={onClose} />
      <VStack p="2.5" space="2.5" style={{ width: '50%' }}>
        <MetaSearchJobInfo details={metaSearchJob.details} />
        <MetaSearchJobOutcomes metaSearchJob={metaSearchJob} />
      </VStack>
    </VStack>
  );
};

const MetaSearchJobInfo = ({ details }: { details: MetaSearchJobDetails }) => {
  const { startDate, endDate, companyIds, routes, cleanFetch } = details;
  const parsedRoutes = routes
    ?.map((route) => `${route.origin} - ${route.destination}`)
    .join(', ');

  return (
    <CardWithHeader
      headerText={translate('dataManagement.metaSearchJobs.searchJobItemInfoHeader')}
    >
      <VStack p="2.5">
        <Field
          label={translate('dataManagement.metaSearchJobs.startDate')}
          value={startDate}
        />
        <Field
          label={translate('dataManagement.metaSearchJobs.endDate')}
          value={endDate}
        />
        {!!companyIds && (
          <Field
            label={translate('dataManagement.metaSearchJobs.companies')}
            value={companyIds?.join(', ')}
          />
        )}
        {!!parsedRoutes && (
          <Field
            label={translate('dataManagement.metaSearchJobs.routes')}
            value={parsedRoutes}
          />
        )}
        <Field
          label={translate('dataManagement.metaSearchJobs.cleanFetch')}
          value={cleanFetch ? 'TRUE' : 'FALSE'}
        />
      </VStack>
    </CardWithHeader>
  );
};

const MetaSearchJobOutcomes = ({
  metaSearchJob,
}: {
  metaSearchJob: MetaSearchJobClient;
}) => {
  const { status, startTime, endTime, outcomes } = metaSearchJob;

  return (
    <VStack>
      <CardWithHeader
        headerText={translate(
          'dataManagement.metaSearchJobs.searchJobItemOutcomesHeader',
        )}
      >
        <VStack p="2.5">
          <Field
            color={status === 'completed' ? 'success-dark' : 'monochrome-dark'}
            label={translate('dataManagement.metaSearchJobs.status')}
            value={status}
          />
          <Field
            label={translate('dataManagement.metaSearchJobs.startDate')}
            value={startTime}
            isDate
          />
          {endTime && (
            <Field
              label={translate('dataManagement.metaSearchJobs.endDate')}
              value={endTime}
              isDate
            />
          )}
        </VStack>
      </CardWithHeader>
      <MetaSearchJobOutcomesTable outcomes={outcomes} />
    </VStack>
  );
};

const MetaSearchJobOutcomesTable = ({
  outcomes,
}: {
  outcomes: MetaSearchJobClient['outcomes'];
}) => {
  const headers = [
    translate('dataManagement.metaSearchJobs.date'),
    translate('dataManagement.metaSearchJobs.origin'),
    translate('dataManagement.metaSearchJobs.destination'),
    translate('dataManagement.metaSearchJobs.sailings'),
    translate('dataManagement.metaSearchJobs.error'),
  ];
  const outcomeTable = outcomes.map((outcome) => {
    return [
      outcome.date,
      outcome.route.origin,
      outcome.route.destination,
      outcome.sailings + '',
      outcome.error,
    ];
  });
  return <Table headers={headers} data={outcomeTable} />;
};
