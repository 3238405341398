import { useAppTheme } from '@droplet-tech-code/core-elements/module/theme';
import { DelayRender } from '@droplet-tech-code/core-elements/module/ui/Delay';
import { DottedLine } from '@droplet-tech-code/core-elements/module/ui/Divider/Divider';
import { FormHandler } from '@droplet-tech-code/core-elements/module/ui/Inputs/Form';
import { VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { ScrollView, View } from '@droplet-tech-code/core-elements/module/ui/View';
import { dayjs } from '@droplet-tech-code/core-elements/module/utils/utils.date';
import {
  isNativeMobile,
  isWeb,
} from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import { PurchaseClientWithMetaWithTickets } from '@naus-code/naus-admin-types';
import { BasketManagerErrorRes } from '@naus-code/naus-client-types';
import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';

import { BookingInfo } from '~/components/Bookings/BookingInfo';
import { CardWithHeader } from '~/components/Card/CardWithHeader';
import { JSONView } from '~/components/JsonView';
import { PageContainer } from '~/components/Page/Page.Container';
import { SwitchStack } from '~/components/Stack/SwitchStack';
import { SupportConversationInputPanel } from '~/components/SupportConversation/InputPanel/Conversation.InputPanel';
import { chatCache } from '~/components/SupportConversation/SupportConversation.utils';
import {
  SupportRequestConversation,
  SupportRequestConversationPreview,
} from '~/components/SupportConversation/SupportRequestConversation';
import { SupportRequestAccordionComponent } from '~/components/SupportRequest/SupportRequestAccordion';
import { useAttachParamsToUrl } from '~/hooks/nav.hook';
import { ScreenPropSupportRequestItem } from '~/navigation/Navigation.types';
import { purchaseApi } from '~/screens/Support/Purchases/Purchases.api';
import { translate } from '~/utils/translation.utils';

import { bookingApi } from '../Bookings/Bookings.api';
import { PurchaseItem } from '../Purchases/PurchaseItem';
import {
  SupportRequestItemHeader,
  SupportRequestItemHeaderSubTitle,
} from './SupportRequest.ItemHeader';
import { supportRequestApi } from './SupportRequests.api';

export const SupportRequestItemScreen = (props: ScreenPropSupportRequestItem) => {
  return <SupportRequestItemNative id={props.route.params.id} />;
};

export const SupportRequestItemNative = ({ id }: { id: string }) => {
  const { data, isLoading } = supportRequestApi.useGetSupportRequestQuery(id ?? '', {
    skip: !id,
  });

  if (data === undefined) {
    return null;
  }

  const check = data.check;
  const bookingId = data.supportRequest.bookingId;
  const currentPurchaseId = data.supportRequest.purchaseId;
  const conversation = data.supportRequest.conversation;

  return (
    <DelayRender isLoading={isLoading}>
      <VStack flex={1}>
        <SupportRequestItemHeader supportRequest={data.supportRequest} check={check} />
        <ScrollView>
          <VStack p="2.5">
            {data ? (
              <CardWithHeader headerText={translate('supportRequests.details')} my="2">
                <View p="2">
                  <SupportRequestItemHeaderSubTitle
                    email={data.supportRequest.p_email}
                    reference={data.supportRequest.ref}
                    dateCreated={data.supportRequest.dateCreated}
                  />
                </View>
              </CardWithHeader>
            ) : null}
            <VStack space="2.5" py="2.5">
              <BookingInfo bookingId={bookingId} supportId={data.supportRequest._id} />
              <SupportRequestPurchasesComponent
                currentPurchaseId={currentPurchaseId}
                bookingId={bookingId}
                supportId={data.supportRequest._id}
              />
            </VStack>
            <VStack py="2.5">
              <SupportRequestConversationPreview
                supportRequestId={id}
                check={check}
                supportConversation={conversation}
              />
            </VStack>
          </VStack>
        </ScrollView>
      </VStack>
    </DelayRender>
  );
};

export const SupportRequestItem = ({
  supportId,
  onClose,
}: {
  supportId: string;
  onClose?: () => void;
}) => {
  useAttachParamsToUrl({ id: supportId });

  const dispatch = useDispatch();
  const { data, refetch } = supportRequestApi.useGetSupportRequestQuery(supportId ?? '', {
    skip: !supportId,
    refetchOnMountOrArgChange: !!supportId,
  });

  const { spacing } = useAppTheme();
  const advancedRef = useRef(new FormHandler());
  const textRef = useRef(new FormHandler());

  const cachedInitText = chatCache.cache[supportId];

  if (data === undefined) {
    return null;
  }

  const check = data.check;
  const bookingId = data?.supportRequest.bookingId;
  const currentPurchaseId = data?.supportRequest.purchaseId;
  const conversation = data?.supportRequest.conversation;
  const basketError = data?.supportRequest.basketError;

  return (
    <>
      <SupportRequestItemHeader
        supportRequest={data.supportRequest}
        check={data.check}
        onClose={onClose}
        refetch={() => {
          refetch();

          if (currentPurchaseId) {
            dispatch(purchaseApi.util.invalidateTags(['getPurchase']));
            dispatch(bookingApi.util.invalidateTags(['getBooking']));
          }
        }}
      />
      <PageContainer bg="monochrome-base">
        <SwitchStack
          flex={1}
          space="2.5"
          style={{ alignSelf: 'stretch', alignItems: 'flex-start' }}
        >
          <VStack style={{ height: '100%' }}>
            <ScrollView
              contentContainerStyle={{
                flexGrow: 1,
                paddingHorizontal: spacing[1],
              }}
            >
              <VStack space="2.5" py="2.5">
                {basketError ? <BasketErrorInfo basketError={basketError} /> : null}
                <BookingInfo bookingId={bookingId} supportId={supportId} />
                <SupportRequestPurchasesComponent
                  currentPurchaseId={currentPurchaseId}
                  bookingId={bookingId}
                  supportId={supportId}
                />
              </VStack>
            </ScrollView>
          </VStack>
          <VStack
            flex={1}
            py="2.5"
            style={[
              { height: '100%', alignItems: 'center' },
              {
                minWidth: 700,
              },
            ]}
            space="2.5"
          >
            <SupportRequestConversation
              supportRequestId={supportId}
              check={check}
              supportConversation={conversation}
            />
            <SupportConversationInputPanel
              supportId={supportId}
              initialText={cachedInitText}
              key={supportId}
              supportRequestRes={data}
              advancedRef={advancedRef}
              textRef={textRef}
            />
          </VStack>
          <VStack
            style={{ width: 400, height: '100%', borderLeftWidth: 1 }}
            bc="monochrome-extraLight"
          >
            <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
              <SupportRequestAccordionComponent data={data} />
            </ScrollView>
          </VStack>
        </SwitchStack>
      </PageContainer>
    </>
  );
};

export const SupportRequestPurchasesComponent = ({
  currentPurchaseId,
  bookingId,
  supportId,
}: {
  currentPurchaseId?: string;
  bookingId?: string;
  supportId?: string;
}) => {
  const { currentData, data, isError } = bookingApi.useGetBookingQuery(bookingId ?? '', {
    skip: !bookingId,
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true,
  });
  const bookingInfo = data?.booking;
  const purchaseListItems =
    bookingInfo?.purchases ?? ([] as PurchaseClientWithMetaWithTickets[]);

  if (currentPurchaseId === undefined) {
    return null;
  }
  if (bookingId === undefined) {
    return null;
  }

  if (bookingInfo === undefined || !bookingId || (!currentData && isError)) {
    return (
      <CardWithHeader
        headerText={`${translate('purchases.purchases')} (0)`}
        w={isWeb ? 600 : undefined}
      ></CardWithHeader>
    );
  }
  // const flag = true;
  return (
    <CardWithHeader
      w={isNativeMobile ? undefined : 600}
      headerText={`${translate('purchases.purchases')} (${purchaseListItems.length})`}
    >
      <VStack space="2.5">
        {purchaseListItems.map((purchase, ix) => {
          return (
            <_SupportRequestPurchaseComponent
              key={purchase._id}
              index={ix}
              purchase={purchase}
              purchaseListItemsLength={purchaseListItems.length}
              supportId={supportId}
              currentPurchaseId={currentPurchaseId}
            />
          );
        })}
      </VStack>
    </CardWithHeader>
  );

  return (
    <CardWithHeader
      w={isNativeMobile ? undefined : 600}
      headerText={`${translate('purchases.purchases')} (${purchaseListItems.length})`}
    >
      <VStack space="2.5">
        {purchaseListItems.map((purchase, ix) => {
          if (currentPurchaseId === purchase._id) {
            return (
              <React.Fragment key={purchase._id + dayjs().toString()}>
                <VStack p="2.5">
                  <PurchaseItem
                    purchaseId={currentPurchaseId}
                    supportId={supportId}
                    withActions
                  />
                </VStack>
                {purchaseListItems.length - 1 !== ix ? <DottedLine /> : null}
              </React.Fragment>
            );
          }
          return (
            <React.Fragment key={purchase._id + dayjs().toString()}>
              <View
                style={[
                  { opacity: 0.5 },
                  purchase.split && {
                    opacity: 1,
                  },
                ]}
                p="2.5"
              >
                <PurchaseItem
                  purchaseId={purchase._id}
                  supportId={supportId}
                  withActions
                />
              </View>
              {purchaseListItems.length - 1 !== ix ? <DottedLine /> : null}
            </React.Fragment>
          );
        })}
      </VStack>
    </CardWithHeader>
  );
};

const _SupportRequestPurchaseComponent = ({
  index,
  purchaseListItemsLength,
  purchase,
  supportId,
  currentPurchaseId,
}: {
  index: number;
  purchaseListItemsLength: number;
  purchase: PurchaseClientWithMetaWithTickets;
  supportId?: string;
  currentPurchaseId: string;
}) => {
  const purchaseId = purchase._id;
  if (currentPurchaseId === purchaseId) {
    return (
      <>
        <VStack p="2.5">
          <PurchaseItem
            purchaseId={currentPurchaseId}
            supportId={supportId}
            withActions
          />
        </VStack>
        {purchaseListItemsLength - 1 !== index ? <DottedLine /> : null}
      </>
    );
  }

  return (
    <>
      <View
        style={[
          { opacity: 0.5 },
          purchase.split && {
            opacity: 1,
          },
        ]}
        p="2.5"
      >
        <PurchaseItem purchaseId={purchase._id} supportId={supportId} withActions />
      </View>
      {purchaseListItemsLength - 1 !== index ? <DottedLine /> : null}
    </>
  );
};

const BasketErrorInfo = ({ basketError }: { basketError: BasketManagerErrorRes }) => {
  return (
    <CardWithHeader headerText="Basket Error" style={[isWeb && { width: 400 }]}>
      <SwitchStack p="2.5" space={isNativeMobile ? '1' : '2.5'}>
        <VStack flex={isNativeMobile ? undefined : 1} space="1">
          <JSONView data={basketError} />
        </VStack>
      </SwitchStack>
    </CardWithHeader>
  );
};
