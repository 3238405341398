import { ConfigForData, SelectedValueItem } from '@droplet-tech-code/core-common-types';
import {
  FormBuilder,
  FormHandler,
} from '@droplet-tech-code/core-elements/module/ui/Inputs/Form';
import { View } from '@droplet-tech-code/core-elements/module/ui/View';
import { dayjs } from '@droplet-tech-code/core-elements/module/utils/utils.date';
import {
  BookingClientWithPurchasesAndTickets,
  PurchaseClientWithMetaWithTickets,
} from '@naus-code/naus-admin-types';
import { useEffect, useRef, useState } from 'react';

import { onReceiveValue } from '~/components/SupportRequest/utils/utils';
import { portLocationApi } from '~/screens/Locations/PortLocations/PortLocations.api';
import { vesselApi } from '~/screens/Vessels/Vessels.api';

import { purchaseActionManager } from '../../../Common/ActionPanel.utils';
import { useManualStore } from '../ManualProvider/Modify.ManualTrip.Provider';

function generateModifyPurchaseManualForm(
  purchase: PurchaseClientWithMetaWithTickets,
  portList: SelectedValueItem[],
  vessels: SelectedValueItem[],
  showAllVessels: boolean,
): ConfigForData {
  const { origin, destination, vesselId } = purchase;

  return [
    {
      items: [
        {
          id: 'origin',
          type: 'single-select',
          label: 'Origin',
          list: portList,
          initialValue: origin,
        },
        {
          id: 'destination',
          type: 'single-select',
          label: 'Destination',
          list: portList,
          initialValue: destination,
        },
        {
          id: 'vesselId',
          type: 'single-select',
          label: 'Vessel',
          list: vessels,
          initialValue: vesselId,
        },
        {
          id: 'showAllVessels',
          type: 'toggle',
          label: 'All vessels',
          initialValue: showAllVessels,
        },
      ],
    },
  ];
}
function generateModifyPurchaseExpandedManualForm(
  purchase: PurchaseClientWithMetaWithTickets,
): ConfigForData {
  const {
    departureDateTime,
    arrivalDateTime,
    providerReservationCode,
    companyReservationCode,
    companyAccessCode,
    valueCcy,
    valueCompanyCcy,
    companyCcy,
    ccy,
  } = purchase;
  return [
    {
      items: [
        {
          id: 'valueCcy',
          type: 'number',
          label: 'Value ' + ccy,
          initialValue: onReceiveValue(valueCcy),
        },
        {
          id: 'valueCompanyCcy',
          type: 'number',
          label: 'Company Value ' + companyCcy,
          initialValue: onReceiveValue(valueCompanyCcy),
        },
        {
          id: 'departureDateTime',
          type: 'date',
          label: 'Departure Datetime',
          format: 'YYYY-MM-DD HH:mm',
          initialValue: departureDateTime,
        },
        {
          id: 'arrivalDateTime',
          type: 'date',
          label: 'Arrival Datetime',
          format: 'YYYY-MM-DD HH:mm',
          initialValue: arrivalDateTime,
        },
      ],
    },
    {
      items: [
        {
          id: 'providerReservationCode',
          type: 'text',
          label: 'Provider Reservation Code',
          initialValue: providerReservationCode,
        },
        {
          id: 'companyReservationCode',
          type: 'text',
          label: 'Company Reservation Code',
          initialValue: companyReservationCode,
        },
        {
          id: 'companyAccessCode',
          type: 'text',
          label: 'Company Access Code',
          optional: !companyAccessCode,
          initialValue: companyAccessCode,
        },
      ],
    },
  ];
}

export const ModifyPurchaseManualForm = ({
  purchase,
}: {
  purchase: PurchaseClientWithMetaWithTickets;
}) => {
  const { data } = vesselApi.useGetVesselListQuery();
  const { data: portData } = portLocationApi.useGetPortLocationListQuery();
  const [showAllVessels, setShowAllVessels] = useState(false);

  const form = new FormHandler();
  const formRef = useRef(form);

  form.subscribeToValueChange((id, value) => {
    if (id === 'showAllVessels') {
      setShowAllVessels(value);
    }
  });

  const manualModStore = useManualStore();
  const addForm = manualModStore((s) => s.addForm);

  useEffect(() => {
    addForm({
      form: formRef.current,
      purchaseId: purchase._id,
      ticketId: '',
    });
  }, []);

  if (!data || !portData) {
    return null;
  }

  const vessels = data
    .filter((vessel) => vessel.operator === purchase.operator || showAllVessels)
    .map((vessel) => {
      return {
        label: vessel.title,
        value: vessel.id,
      };
    });

  const portList = portData.map((port) => {
    return { label: port.title, value: port.id };
  });

  const config = generateModifyPurchaseManualForm(
    purchase,
    portList,
    vessels,
    showAllVessels,
  );

  return (
    <View style={{ marginBottom: -30, flex: 1 }}>
      <FormBuilder
        key={showAllVessels ? 'true' : 'false'}
        data={config}
        form={formRef.current}
      />
    </View>
  );
};

export const BookingModificationOffsetCharge = ({
  booking,
}: {
  booking: BookingClientWithPurchasesAndTickets;
}) => {
  const formRef = useRef(new FormHandler());

  const [config, setConfig] = useState<{ id: string; config: ConfigForData } | undefined>(
    undefined,
  );

  const [selectedReservation] =
    purchaseActionManager.state.useGlobalState('selectedReservation');
  const [selectedTickets] = purchaseActionManager.state.useGlobalState('selectedTickets');

  const manualModStore = useManualStore();
  const addForm = manualModStore((s) => s.addForm);

  useEffect(() => {
    addForm({
      form: formRef.current,
      purchaseId: booking.booking_ref,
      ticketId: '',
    });
    const purchases = booking.purchases.filter(
      (purchase) => purchase.companyReservationCode === selectedReservation,
    );
    const total = purchases.reduce(
      (cache, current) => (cache += current.valueCompanyCcy),
      0,
    );
    const currency = purchases?.at(0)?.companyCcy;

    setConfig({
      id: selectedReservation,
      config: [
        {
          items: [
            {
              id: 'operatorFareDifference',
              label: `Operator fare difference + Operator fee, in ${currency} (Original total booking value ${onReceiveValue(
                total,
              )}${currency})`,
              type: 'number',
              initialValue: 0,
            },
          ],
        },
        {
          items: [
            {
              id: 'amendmentDatetime',
              label: `Date and time amendment was made (YYYY-MM-DD HH:mm)`,
              type: 'date',
              format: 'YYYY-MM-DD HH:mm',
              initialValue: dayjs().format('YYYY-MM-DD HH:mm'),
            },
          ],
        },
      ],
    });
  }, [selectedReservation, selectedTickets]);

  if (!config) {
    return null;
  }

  return (
    <View style={{ marginBottom: -30, flex: 1 }}>
      <FormBuilder key={config.id} data={config.config} form={formRef.current} />
    </View>
  );
};

export const ModifyPurchaseExpandedManualForm = ({
  purchase,
}: {
  purchase: PurchaseClientWithMetaWithTickets;
}) => {
  const config = generateModifyPurchaseExpandedManualForm(purchase);
  const formRef = useRef(new FormHandler());

  const manualModStore = useManualStore();
  const addForm = manualModStore((s) => s.addForm);

  useEffect(() => {
    addForm({
      form: formRef.current,
      purchaseId: purchase._id,
      ticketId: '',
    });
  }, []);

  return (
    <View style={{ marginBottom: -30, flex: 1 }}>
      <FormBuilder data={config} form={formRef.current} />
    </View>
  );
};
